<template>
  <v-sheet
      v-if="selectedChatId || !SM"
      class="fill-height"
      :class="[{'fullscreen' : SM }]"
  >
    <v-row class="no-gutters flex-wrap flex-column fill-height overflow-x-hidden" >

      <!-- Messages Header -->
      <v-col cols="auto" class="shrink " >
        <!-- Desktop Header -->
        <div v-if="!compact && !SM && selectedChatId " class="pa-5 pb-0">
          <div class="d-flex justify-space-between align-center ">
            <div class="d-flex align-center justify-space-between" style="width: 100%">
              <div class="d-flex align-center">
                <v-avatar class="mr-3" size="40" :color="getChatAvatarColor(selectedChat.type)">
                  <v-img  v-if="selectedChat.chat_img"
                          transition="xxx"
                          :src="selectedChat.chat_img"></v-img>
                  <v-icon v-else dark>{{  getChatIcon(selectedChat.type)  }}</v-icon>
                </v-avatar>
                <div v-if="selectedChat">
                  <h4 > <span class="font-weight-regular" v-if="selectedManager.uuid"> {{ $t('ManagerChatWithStudent') }}: </span> {{ selectedChat.name }} </h4>
                  <h4  v-if="selectedManager.uuid"  > <span class="font-weight-regular">{{ $t('Manager') }} :</span>  {{ selectedManager.name }} </h4>

                </div>
                <div v-else>
                  <h4 > {{ $t('NoChatSelected') }} </h4>
                </div>
              </div>
              <div v-if="selectedChat && ( selectedChat.type === 'dynamic_chat' || selectedChat.type === 'chat') " >
                <v-btn @click="openChatUsers" class="noCaps" text :color="wsACCENT">
                  <v-icon class="mr-2">mdi-account-multiple</v-icon>
                  {{ $tc('users' , selectedChat.users) }}
                </v-btn>
              </div>

              <v-btn @click="openDeleteOldChat()" v-if="oldChatCondition" dark class="noCaps" :color="wsWARNING">
                <v-icon>mdi-delete</v-icon>
                {{ $t('DeleteChat') }}
              </v-btn>
            </div>
          </div>

          <v-sheet
              v-if="oldChatCondition"
              :color="wsLIGHTCARD"
              class="wsRoundedLight mt-3 pa-3"
          >
            <h6 class="font-weight-regular d-flex ">
              <v-icon :color="wsATTENTION" class="mr-2">mdi-information</v-icon>
              {{ $t('OldChatTooltip') }}
            </h6>

          </v-sheet>

          <v-divider :style="`border-color : ${wsBACKGROUND}`" class="mt-5"  />
        </div>
        <!-- Mobile Header -->
        <div v-if="SM">
          <div v-if="selectedChatId"
               style="width: 100%"
               class="d-flex align-center justify-space-between pa-2" >

            <v-btn
                v-if="selectedChatId"
                :color="wsACCENT"
                @click="returnBack"
                :style="`background-color: ${wsBACKGROUND}`"
                outlined
                small
                icon
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <h5 :style="wsDARKER" class="text-center px-1"> {{ selectedChat.name }}   </h5>

            <div v-if="selectedChat && ( selectedChat.type === 'dynamic_chat' || selectedChat.type === 'chat') " >
              <v-btn @click="openChatUsers" class="noCaps" icon :color="wsACCENT">
                <v-icon class="mr-2">mdi-dots-horizontal</v-icon>
              </v-btn>
            </div>

            <v-avatar v-else class="mr-3" size="32" :color="getChatAvatarColor(selectedChat.type)">
              <v-img  v-if="selectedChat.chat_img" :src="selectedChat.chat_img" transition="xxx"></v-img>
              <v-icon v-else dark>{{  getChatIcon(selectedChat.type)  }}</v-icon>
            </v-avatar>

          </div>
          <v-divider :style="`border-color : ${wsBACKGROUND}`"  />

        </div>

      </v-col>

      <!-- Messages Content -->
      <v-col cols="auto" class="grow" style="height: 100px"
             :class="!(compact || SM) ? 'py-3' : '' "
      >
        <v-sheet   class=" overflow-y-auto fill-height"
                   :class="!(compact || SM) ? 'pa-6' : 'pa-3' "
                   id="message_view"
                   ref="message_view_ref">

          <!-- Messages Table -->
          <div v-if="selectedChatId" >

            <chat-message
                v-for="(message,index) in $store.state.messenger.chatMessages "
                @reply="setReplyMessage"
                @edit="openEditMessage"
                @delete="$store.state.messenger.chatMessages.splice($event , 1)"
                :key="index + 'message'"
                :message="message"
                :index="index"
            />

          </div>
          <div v-else class="d-flex fill-height align-center justify-center">
            <h1 class="text-center " > {{ $t('NoChatSelected') }} </h1>
          </div>

        </v-sheet>
      </v-col>

      <!-- Messages Text Field -->
      <v-col cols="auto" class="shrink" style="max-height: 350px" >
        <!-- Reply Message Field -->
        <v-sheet
            v-if="replyMessage.text"
            :color="wsLIGHTCARD"
            :class="[{'mx-5' : !SM}]"
            :style="`border${SM ? '-top' : ''} : 1px solid ${wsBACKGROUND}`"
            class="wsRoundedLight pa-3"
        >

          <div class="d-flex justify-space-between">
            <h5 :style="`color : ${wsACCENT}`"> {{ $t('ReplyMessage')}}:</h5>
            <v-btn small @click="cancelReplyMessage" icon :color="wsDARKER">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="d-flex align-center mt-1">
            <v-img v-if="replyMessage.type === 'image'" height="48" max-width="48" width="48" :src="replyMessage.file" class="wsRoundedLight mr-3" transition="xxx" />
            <v-divider :style="`border-color : ${wsDARKLIGHT}`" class="mr-3" vertical />
            <div>
              <h6 :style="`color : ${wsACCENT}`" > {{ replyMessage.sender_name }} </h6>
              <h6 v-if="replyMessage.type === 'text'" class="font-weight-regular" v-html="replyMessage.text" />
              <h6 v-if="replyMessage.type === 'image'" class="font-weight-regular" > {{ $t('Image') }} </h6>
            </div>
          </div>
        </v-sheet>
        <!-- Edit Message Field -->
        <v-sheet
            v-if="editMessage.text"
            :color="wsLIGHTCARD"
            :class="[{'mx-5' : !SM}]"
            :style="`border${SM ? '-top' : ''} : 1px solid ${wsBACKGROUND}`"
            class="wsRoundedLight pa-3"
        >

          <div class="d-flex justify-space-between">
            <h5 :style="`color : ${wsACCENT}`"> {{ $t('EditMessage')}}:</h5>
            <v-btn small @click="cancelEditMessage" icon :color="wsDARKER">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="d-flex align-center mt-1">
            <v-divider :style="`border-color : ${wsDARKLIGHT}`" class="mr-3" vertical />
            <h6 class="font-weight-regular" v-html="цу" />
          </div>
        </v-sheet>

        <v-sheet
            class="d-flex align-center "
            :class="[{ 'pa-5' : !SM }]"
        >

          <v-slide-x-transition mode="out-in">
            <!-- Text field -->
            <v-sheet
                v-if="!displayUpload"
                :style=" !SM ? `border : 1px solid ${wsBACKGROUND} !important` : `border-top : 1px solid ${wsBACKGROUND} !important`"
                :class="[{ 'pa-3' : !SM } , { 'pa-2' : SM }]"
                class="wsRoundedLight "
                key="message"
                width="100%"
            >


              <v-sheet
                  color="transparent"
                  :class="SM ? 'd-flex justify-space-between' : null"

              >
                <ws-file-uploader
                    v-if="SM && !editMessage.text"
                    activator
                    activator-icon="mdi-image-plus"
                    @success="uploadImage"
                    image
                    public
                />
                <v-textarea
                    key="text"
                    v-model="entityData.text"
                    @keydown.enter="handleEnter"
                    :placeholder="$t('EnterMessage')"
                    :disabled="!selectedChatId"
                    single-line
                    :row-height="1"
                    style="font-size: 0.83em; font-weight: 450; line-height: 1.2 !important; margin-top: -2px"
                    background-color="transparent"
                    auto-grow flat solo
                    dense
                    hide-details
                >
                </v-textarea>
                <v-btn
                    v-if="SM"
                    @click="sendMessage()"
                    :disabled="!selectedChatId || !entityData.text"
                    :color="wsACCENT"
                    class="noCaps"
                    elevation="0"
                    :style="`background-color: ${!selectedChatId || !entityData.text ? '#e0e0e0' : wsBACKGROUND}`"
                    icon
                >
                  <v-icon size="22" class="mr-n1" >mdi-send</v-icon>
                </v-btn>
                <div class="d-flex justify-end">
                  <v-btn
                      v-if="!SM && !editMessage.text"
                      :color="wsATTENTION"
                      @click="displayUpload = true"
                      style="min-width: 38px; border-radius: 50%"
                      class="noCaps mr-3  px-0"
                      height="38"
                      elevation="0"
                      dark
                  >
                    <v-icon size="24"  >mdi-image-plus</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="!SM"
                      :disabled="!selectedChatId || !entityData.text"
                      @click="sendMessage()"
                      :color="wsATTENTION"
                      class="noCaps"
                      elevation="0"
                      :dark="!!selectedChatId && !!entityData.text"
                  >
                    {{  $t('Send') }}
                    <v-icon class="mr-n1" >mdi-chevron-right</v-icon>
                  </v-btn>

                </div>

              </v-sheet>
            </v-sheet>
            <!-- Image Upload field -->
            <v-sheet
                key="upload" v-else  color="transparent" min-height="230"  width="100%">
              <v-btn  @click="displayUpload = false"
                      :color="wsDARK"
                      block
                      outlined
                      class="noCaps mb-5"   >{{ $t('Back') }}</v-btn>
              <ws-file-uploader
                  @success="uploadImage"
                  image
                  public
              />

            </v-sheet>
          </v-slide-x-transition>

        </v-sheet>

      </v-col>

      <!-- Dialogs  -->
      <edit-chat-dialog
          v-if="displayChatUsers"
          v-model="displayChatUsers"
          :delete-old="displayDeleteOldChat"
      />

    </v-row>
  </v-sheet>
</template>

<script>
import editChatDialog from "@/modules/messenger/components/UI/editChatDialog";
import {mapActions, mapState} from "vuex";
import chatMessage from "@/modules/messenger/components/UI/chatMessage";
export default {
  name: "messengerChat",
  components : {
    editChatDialog,
    chatMessage
  },
  props : {
    compact : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      displayUpload : false,
      entityData : {
        type : 'text'
      },
      search : '',
      chatUsers : [],
      displayChatUsers : false,
      displayDeleteOldChat : false,
      editMessage : {},
      replyMessage : {},
      editMessageIndex : 0
    }
  },
  computed : {
    ...mapState('messenger', [
        'selectedChatId',
        'selectedChat',
        'activeChats',
        'chatMessages',
        'selectedManager'
    ]),
    ...mapState('auth', [ 'userID','userImg']),

    oldChatCondition() {
      return !['contact_fine_business_support' , 'personal' , 'dynamic_chat' , 'chat']
          .includes(this.selectedChat.type) &&  (this.IS_BUSINESS_MANAGER || this.IS_BUSINESS_OWNER)
    },

  },
  watch : {
    selectedChatId(uuid)  {
      this.editMessage = {}
      this.editMessageIndex = null
      if ( uuid ) {
        this.getChatMessages()
      }
    },
    chatMessages(){
      this.$nextTick(() => {
        var container = this.$el.querySelector("#message_view");
        container.scrollTop = container.scrollHeight;
      } );
    }
  },
  methods : {
    ...mapActions('messenger', [
      'GET_CHATS',
      'GET_CHAT_MESSAGES' ,
      'SEND_MESSAGE',
      'GET_CHAT_USERS',
    ] ),

    setReplyMessage(replyMessage) {
      this.cancelEditMessage()
      this.replyMessage = this.COPY(replyMessage)
      this.entityData.reply_to_id = replyMessage.uuid
    },
    cancelReplyMessage() {
      this.replyMessage = {}
      delete this.entityData.reply_to_id
    },
    cancelEditMessage() {
      this.editMessage = {}
      this.editMessageIndex = null
      this.entityData = {}
    },
    openEditMessage(index) {
      this.cancelReplyMessage()
      this.editMessageIndex = index
      this.editMessage = this.COPY(this.chatMessages[index])
      this.entityData.text = this.chatMessages[index].text
      this.entityData.uuid = this.chatMessages[index].uuid
      this.entityData = this.COPY(this.entityData)
    },

    openDeleteOldChat() {
      this.displayChatUsers = true
      this.displayDeleteOldChat = true
    },

    getChatAvatarColor(type) {
      switch(type) {
        case 'chat' : return this.wsDARKLIGHT
        case 'dynamic_chat' : return this.wsDARK
        default : return this.wsLIGHTACCENT
      }
    },

    getChatIcon(type) {

      switch(type) {
        case 'chat' : return 'mdi-account-group'
        case 'dynamic_chat' : return 'mdi-bank'
        default : return 'mdi-account'
      }

    },


    async uploadImage(result) {
      this.entityData.text = 'image'
      this.entityData.file  = result.url
      this.entityData.file_id  = result.uuid
      await this.sendMessage('image')
      this.displayUpload = false
    },

    async openChatUsers() {
      this.displayDeleteOldChat = false
      this.displayChatUsers = true
    },

    returnBack() {
      this.$store.state.messenger.selectedChatId = ''
      this.$store.state.messenger.selectedChat = {}
    },
    async sendMessage(type = 'text') {
      if ( this.LOADING ) {
        return
      }
      if ( !this.editMessage.uuid ) {
        delete this.entityData.uuid
      }
      this.editMessage = {}
      this.entityData.type = type

      if (this.entityData.type === 'text' && !this.entityData.text) { return }

      this.entityData.messenger_chat_id = this.selectedChatId

      let result = await this.SEND_MESSAGE(this.entityData)
      if ( !result  ) {
        this.editMessage = {}
        this.editMessageIndex = null
        return
      }
      this.displayUpload = false
      if ( this.entityData.uuid ) {
        this.chatMessages[this.editMessageIndex].text = this.entityData.text
        this.chatMessages[this.editMessageIndex].is_edited = true
        this.entityData = {
          text : '',
          file : null
        }
        return
      }

      this.cancelEditMessage()
      this.cancelReplyMessage()

      this.$store.state.messenger.chatMessages.push(result)

      let chat = this.activeChats.find(el=>el.uuid === this.selectedChatId );
      if ( chat) {
        let text = this.entityData.text.length > 20 ? (this.entityData.text.slice(0,20) + '...') : this.entityData.text

        chat.last_message_preview = this.entityData.type === 'text' ? text : this.$t('File')
        chat.last_message_date = result.date_created

      }
      this.$store.state.messenger.activeChats = this.$store.state.messenger.activeChats.filter( el=> el !== chat )
      this.$store.state.messenger.activeChats.unshift(chat)

      this.entityData = {
        text : '',
        file : null
      }

    },

    async getChatMessages() {
      if ( this.selectedChatId ) {

        let result = await this.GET_CHAT_MESSAGES(this.selectedChatId)
        if (!result || result === true ) { this.chatMessages = [];  return  }
        this.$store.state.messenger.newMessagesCount -= this.selectedChat.new_messages_count
        let chat = this.$store.state.messenger.activeChats.find(el=>el.uuid === this.selectedChatId)
        if ( chat ) {
          chat.new_messages_count = 0
        }

        this.$store.state.messenger.chatMessages = result || []
      }
    },
    // technical
    handleEnter(e){
      if(!e.shiftKey) {
        e.preventDefault();
        this.sendMessage()
      }
    },
    getImageCondition(item) {
      if ( item.sender === this.userID ) {
        if ( this.userImg ) { return true }
      } else {
        if (this.selectedChat.img) { return true }
      }
      return false
    }
  },
  mounted() {
    this.getChatMessages()
  }
}
</script>

<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
</style>